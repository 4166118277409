import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import ContactForm from './ContactForm';
import CookieConsent from 'react-cookie-consent';

function App() {
  const [showConsent, setShowConsent] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(false);
  useEffect(() => {
    checkCookieConsent();
  }, []);

  const checkCookieConsent = async () => {
    if ('cookieStore' in window) {
      try {
        const status = await window.cookieStore.getAll();
        setConsentAccepted(status.length > 0);
      } catch (error) {
        console.error('Error checking cookie consent:', error);
      }
    } else {
      // Fallback for browsers that don't support the Cookies API
      const consentCookie = document.cookie.split(';').find((c) => c.trim().startsWith('cookieConsent='));
      setConsentAccepted(!!consentCookie);
    }
  };

  const enableAnalytics = async () => {
    if ('cookieStore' in window) {
      try {
        await window.cookieStore.set('cookieConsent', 'true');
      } catch (error) {
        console.error('Error setting cookie consent:', error);
        return;
      }
    } else {
      // Fallback for browsers that don't support the Cookies API
      document.cookie = 'cookieConsent=true; max-age=31536000; path=/';
    }

    console.log('Google Analytics Enabled!');
    // Load Google Analytics script after user gives consent
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-VJX3YX0REM`;
    script.async = true;
    document.body.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-VJX3YX0REM');
  };
  const handleAccept = () => {
    enableAnalytics();
    setShowConsent(false);
    setConsentAccepted(true);
  };
  const backgroundImageStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/header.jpg)`,
  };

  const marqueeRef = useRef(null);
  const intervalRef = useRef(null);
  const scrollDirectionRef = useRef(1); // 1 for right (scrolling forward), -1 for left (scrolling back)
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollStart = useRef(0);

  // Function to start the auto-scrolling with bouncing effect
  const startAutoScroll = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (marqueeRef.current) {
        let newScrollLeft = marqueeRef.current.scrollLeft + scrollDirectionRef.current;

        // Check bounds and reverse direction if needed
        if (newScrollLeft >= marqueeRef.current.scrollWidth - marqueeRef.current.clientWidth) {
          scrollDirectionRef.current = -1; // Change direction to left
        } else if (newScrollLeft <= 0) {
          scrollDirectionRef.current = 1; // Change direction to right
        }

        marqueeRef.current.scrollLeft = newScrollLeft;
      }
    }, 20);
  };

  useEffect(() => {
    startAutoScroll();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleStart = (position) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    isDragging.current = true;
    startX.current = position;
    scrollStart.current = marqueeRef.current.scrollLeft;
  };

  const handleMove = (position) => {
    if (!isDragging.current) return;
    const walk = position - startX.current;
    marqueeRef.current.scrollLeft = scrollStart.current - walk;
  };

  const handleEnd = () => {
    isDragging.current = false;
    startAutoScroll();
  };

  // Mouse event handlers
  const handleMouseDown = (e) => {
    handleStart(e.pageX);
    e.preventDefault(); // Prevent default actions like text selection
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      // Check if the primary button is pressed
      handleMove(e.pageX);
    }
  };

  const handleMouseUp = () => {
    handleEnd();
  };

  // Touch event handlers
  const handleTouchStart = (e) => {
    handleStart(e.touches[0].pageX);
    e.preventDefault(); // Prevent default page scrolling
  };

  const handleTouchMove = (e) => {
    handleMove(e.touches[0].pageX);
    e.preventDefault(); // Continue to prevent default page scrolling
  };

  const handleTouchEnd = () => {
    handleEnd();
  };

  const mapImageRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    const updateMarkerPosition = () => {
      const mapImg = mapImageRef.current;
      if (mapImg && markerRef.current) {
        const imgWidth = mapImg.offsetWidth;
        const imgHeight = mapImg.offsetHeight;

        // Adjust these based on where you need the marker to be
        const markerXPercentage = 22.5; // example: 50% of the width
        const markerYPercentage = 51.5; // example: 50% of the height

        markerRef.current.style.left = `${(imgWidth * markerXPercentage) / 100}px`;
        markerRef.current.style.top = `${(imgHeight * markerYPercentage) / 100}px`;
      }
    };

    window.addEventListener('resize', updateMarkerPosition);
    updateMarkerPosition(); // Initial position update

    return () => {
      window.removeEventListener('resize', updateMarkerPosition);
    };
  }, []);

  return (
    <div className="App">
      <div className="announcement">
        <div className="trapezoid">
          <p>We are currently under construction. Please check back soon for updates.</p>
        </div>
      </div>
      <header className="App-header" style={backgroundImageStyle}>
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to Camp Waluhi'Yi!</p>
        <div className="chevron">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2.20277L19.8891 0L11.0738 9.55609L2.07326 0.196619L0 2.47772L11.1476 14L22 2.20277Z" fill="#F6F1E4"></path>
          </svg>
        </div>
      </header>
      <div className="container">
        <section className="mission-section">
          <h1>Mission</h1>
          <p>Provide secure facilities for camping and outdoor nature experience for the youth and adults of the City of Duncan and Stephens County</p>
          <p>
            Our goal is to create a welcoming environment where families and individuals can enjoy scenic viewpoints, hike our trails, and relax by our man-made lake, fostering a deeper connection
            with nature.
          </p>
        </section>
        <section className="announcement-section">
          <div className="announcement-container">
            <img src={process.env.PUBLIC_URL + '/images/waluhiyi_fall_2024_open_house.jpeg'} alt="Fall 2024 Open House" className="announcement-image" />
          </div>
        </section>
        <section className="location-section">
          <div className="map">
            <a href="https://maps.app.goo.gl/cmg1ckMrJgwmARWR8" target="_blank" rel="noopener noreferrer">
              <img ref={mapImageRef} src={process.env.PUBLIC_URL + '/images/googlemap.png'} alt="location" className="map-image" />
              <div ref={markerRef} className="map-marker">
                <span className="marker-text">Camp Waluhi'Yi</span>
                <div className="marker-arrow"></div>
              </div>
            </a>
            <p>Click the map to route to us!</p>
          </div>
          <div className="map-info">
            <h1>Location:</h1>
            <p>Located at 290498 E 1715 Rd, Marlow, OK 73055</p>
          </div>
        </section>
        <div
          className="image-marquee-wrapper"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="image-marquee" ref={marqueeRef}>
            <img src={process.env.PUBLIC_URL + `/images/image1.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image2.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image3.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image4.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image5.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image6.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image7.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image8.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image9.jpg`} alt="Description" />
            <img src={process.env.PUBLIC_URL + `/images/image10.jpg`} alt="Description" />
          </div>
        </div>
        <section className="amenities-section">
          <h1>Amenities</h1>
          <div className="amenities-boxes">
            <div className="amenities-box">
              <h1>Facilities</h1>
              <ul>
                <li>Campsites</li>
                <li>Cabins</li>
                <li>Shelters</li>
                <li>Restrooms</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Recreation</h1>
              <ul>
                <li>Swimming</li>
                <li>Gaga Ball</li>
                <li>Canoes</li>
                <li>Kayaks</li>
                <li>Fishing</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Comfort</h1>
              <ul>
                <li>Electricity</li>
                <li>Fire Rings</li>
                <li>BBQ Grills</li>
                <li>Water Spigots</li>
              </ul>
            </div>
            <div className="amenities-box">
              <h1>Scenery</h1>
              <ul>
                <li>Scenic Viewpoints</li>
                <li>Hiking Trails</li>
                <li>Picnic Areas</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="contact-info">
            <h1>Contact Us</h1>
            <p>For more information, please contact us at:</p>
            <p>Phone: (580)-255-5511</p>
            <p>Email: campwaluhiyi@gmail.com</p>
          </div>
          <ContactForm />
        </section>
        <section className="history-section">
          <h1>History</h1>
          <ul>
            <li>
              <p>Founded in 1953 when the Duncan Girl Scout Council leased 65 acres from the City of Duncan</p>
            </li>
            <li>
              <p>The Quonset hut, known at the time as the “lodge”, was constructed around that time</p>
            </li>
            <li>
              <p>In 1967 the Girl Scouts became a part of the Sooner Girl Scout Council and the camp was transferred to the Camp Waluhi'Yi Association, a non-profit corporation</p>
            </li>
            <li>
              <p>
                The camp grew to include a garage, tool shed, a primitive cabin that today we call the “Pioneer Cabin”, the administrative/first aid building and six cabins today called the “Girl
                Scout Cabins”
              </p>
            </li>
            <li>
              <p>At one time there were : two camping areas with six tent platforms each, a Canoeing beach, and an Archery range</p>
            </li>
            <li>
              <p>Historical documents indicate the camp was available to groups wanting to use the camp at a fee of $2.00 per night</p>
            </li>
          </ul>
        </section>
      </div>
      <footer id="main-footer">
        <div class="footer-content">
          <div class="footer-section contact">
            <h3>Contact Us</h3>
            <p>Phone: (580)-255-5511</p>
            <p>Email: campwaluhiyi@gmail.com</p>
            <p>Address: 290498 E 1715 Rd, Marlow, OK 73055</p>
          </div>
        </div>
        <div class="footer-bottom">&copy; 2024 Waluhi'yi</div>
      </footer>

      {!consentAccepted && (
        <div className="cookie-icon" onClick={() => setShowConsent(!showConsent)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48" fill="#D2B48C">
            <path d="M480 976q-83 0-155.5-31.5T198 858q-55-55-86.5-127.5T80 576q0-74 28-139.5T186 326q56-57 130.5-86.5T456 210q-5 30 4.5 58.5T496 320q27 25 63 28t61-10q-3 35 8.5 65.5T664 455q20 17 45.5 25.5T760 488q-9 31 2.5 61t33.5 49q30 20 68 21-15 98-60.5 179.5T718 883q-57 57-130 87.5T480 976ZM360 826q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm-70-250q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm280 140q17 0 28.5-11.5T610 676q0-17-11.5-28.5T570 636q-17 0-28.5 11.5T530 676q0 17 11.5 28.5T570 716Zm110-170q17 0 28.5-11.5T720 506q0-17-11.5-28.5T680 466q-17 0-28.5 11.5T640 506q0 17 11.5 28.5T680 546Zm-220-30q17 0 28.5-11.5T500 476q0-17-11.5-28.5T460 436q-17 0-28.5 11.5T420 476q0 17 11.5 28.5T460 516Z" />
          </svg>
        </div>
      )}

      {showConsent && (
        <div className="cookie-bubble">
          <div className="cookie-consent-container">
            <div className="cookie-consent-content">
              <p>This website uses cookies to enhance your experience. By continuing, you agree to our cookie policy.</p>
            </div>
            <button className="cookie-consent-button" onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
